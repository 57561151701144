body {
  height: 100vh;
  color: #ffffff;
  /* background-color: #231f20; */
  background-color: black;
  scroll-behavior: smooth;
}

.App {
  width: 100%;
  height: 100%;
  font-family: "Google Sans", Roboto, arial, sans-serif;
}

:root {
  cursor: default;
  font-size: 16px;
  --font-primary: "Google Sans", Roboto, arial, sans-serif;
  --font-weight-primary: normal;
  --font-weight-bold: bold;
  --text-primary: white;
  --text-secondary: #f2f3f4;
  --button-primary: #f36f42;
  --button-secondary: #ee3e35;
  --bg-alert-primary: #1976d2;
  --title-font-size: 36px;
}

body::-webkit-scrollbar {
  width: 0.4rem;
}

body::-webkit-scrollbar-track {
  background: #333333;
  filter: opacity(.5);
}

body::-webkit-scrollbar-thumb {
  background: #BEBEBE;
  filter: grayscale(100%);
}

body::-webkit-scrollbar-thumb:hover {
  background: var(--button-secondary);
  cursor: pointer;
}