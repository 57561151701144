.Nav {
   position: fixed;
   top: 0;
   background-color: black;
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   z-index: 4;
}

.navbar {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 80vw;
   height: 100px;
   padding-left: 10vw;
   padding-right: 10vw;
}

.announcement-container {
   display: flex;
   align-items: center;
   margin-right: 1rem;
}

.announcement {
   padding: 1rem 0rem;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   background-color: var(--bg-alert-primary);
   color: var(--text-primary);
   cursor: pointer;
   z-index: 2;
}

div span {
   padding-left: 1rem;
   padding-right: 1rem;
}

.nav-items {
   list-style: none;
   display: flex;
   justify-content: space-between;
   margin: 0;
   padding: 0;
}

#contact-button {
   background-image: linear-gradient(90deg, #f36f42, #ee3e35);
   filter: opacity(1) brightness(1);
   padding: .75rem 1.5rem;
   border-radius: .5rem;
   border: none;
}

.link {
   text-decoration: none;
   color: var(--text-primary);
   font-size: 16px;
   font-family: var(--font-primary);
   font-weight: 500;
   /* text-transform: uppercase; */
}

.link:hover {
   cursor: pointer;
}

.branding {
   display: flex;
   align-items: center;
   font-family: var(--font-primary);
   text-transform: uppercase;
   font-size: 24px;
   font-weight: var(--font-weight-bold);
   cursor: pointer;
}

.branding a {
   padding: 1rem;
   color: var(--text-secondary);
}

#logo {
   height: 45px;
}

@media only screen and (max-width: 600px) {
   .branding {
      font-size: 16px;
   }

   .branding a {
      padding: 4px;
   }

   #logo {
      height: 30px;
   }

   #contact-button {
      height: 30px;
      width: 90px;
      padding: 0;
   }

}