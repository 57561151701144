.Footer {
   display: flex;
   justify-content: center;
   align-items: center;
   padding-bottom: 20px;
}

.Footer a {
   color: white;
   text-decoration: none;
   font-family: var(--font-primary);
}