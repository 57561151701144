main {
   display: flex;
   flex-direction: column;
   padding-left: 10vw;
   padding-right: 10vw;
   z-index: -1;
}

section {
   display: flex;
   flex-direction: column;
   justify-content: center;
   width: 100%;
   min-height: 100vh;
   font-family: var(--font-primary);
}

#home {
   background-image: url('../../assets/data-interconnected-symbol.svg');
   background-repeat: no-repeat;
   background-size: 300px 300px;
   background-position: center;
   display: flex;
   flex-direction: column;
   height: auto;
}

#title{
   font-family: var(--font-primary);
   font-size: var(--title-font-size);
}

section p {
   line-height: 1.6rem;
   font-family: var(--font-primary);
   font-size: 16px;
   box-sizing: border-box;
}

#home img {
   position: absolute;
   bottom: 7vh;
   align-self: center;
   z-index: -3;
}

#product {
   height: auto;
}

#contact {
   height: auto;
}

.form-container {
   display: flex;
   flex-direction: column;
}

input {
   background: black;
   border: none;
   color: white;
   padding: 8px;
   box-sizing: border-box;
   font-family: var(--font-primary);
}

label {
   display: block;
}

.input {
   outline: none;
   border: none;
   overflow: hidden;
   margin: 0;
   width: 100%;
   padding: 0.25rem 0;
   background: none;
   color: white;
   font-size: 18px;
   font-weight: 600;
}

/* .email-input:valid {
   color: yellowgreen;
} */

.email-input:invalid {
   color: orangered;
}

input, select, textarea {
   padding: 0.4em;
   margin: 0 0 0.5em 0;
   box-sizing: border-box;
   border: 1px solid #ccc;
   border-radius: 2px;
}

.form-grid {
   display: grid;
   position: relative;
   gap: 2rem;
   grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
}

.field-card {
   display: flex;
   flex-direction: column;
   align-items: center;
   box-sizing: border-box;
}

.field {
   width: 100%;
   max-width: 500px;
   position: relative;
   border-bottom: 2px dashed white;
   margin: 4rem auto 1rem;
}

.field::after {
   content: '';
   position: relative;
   display: block;
   height: 4px;
   width: 100%;
   background: linear-gradient(90deg, #f36f42, #ee3e35);
   transform: scaleX(0);
   transform-origin: 0%;
   transition: transform 500ms ease;
   top: 2px;
}

.field:focus-within {
   border-color: transparent;
}

.field:focus-within::after {
   transform: scaleX(1);
}

.label {
   z-index: -1;
   position: absolute;
   transform: translateY(-2rem);
   transform-origin: 0%;
}

.field:focus-within .label,
.input:not(:placeholder-shown) + .label {
   transform: scale(0.8) translateY(-5rem);
}

.field:focus-within .multi-line-label,
.multi-line-input:not(:placeholder-shown) + .multi-line-label {
   transform: scale(0.8) translateY(-10rem);
}

textarea {
   resize: none;
}

.grid {
   display: grid;
   gap: 3rem;
   grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
}


.card {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
   box-sizing: border-box;
   height: 200px;
   max-height: 200px;
}

.card p {
   width: auto;
   text-align: start;
}

.card img {
   height: 45px;
}

#contact button {
   background: linear-gradient(90deg, #f36f42, #ee3e35);
   filter: opacity(1) brightness(1);
   padding: .75rem 1.5rem;
   border-radius: 2rem;
   border: none;
   color: white;
   align-self: center;
   margin: 2rem auto 4rem auto;
   display: flex;
}

#contact button img {
   height: 16px;
   padding: 0 8px 0 0px;
}

/* laptop screens and bigger */ 
@media only screen and (min-width: 1024px) {
   #title {
      font-size: 42px;
   }

   main section p {
      font-size: 18px;
   }
}

/* large screens only */
@media only screen and (min-width: 600px) {
   section p {
      width: 33rem;
   }
}

/* small screens */
@media only screen and (max-width: 600px) {
   br {
      display: none;
   }
}

/* phone screens */
@media only screen and (max-width: 425px) {
   #title {
      font-size: 30px;
   }
   .grid {
      margin: 9rem 0 9rem 0;
      gap: 6rem;
   }
   .grid img {
      height: 30px;
   }
   #contact button {
      border-radius: 2rem;
      /* background: black; */
   }
}

/* medium phones */
@media only screen and (max-width: 375px) {
   #title {
      margin-top: 8rem;
   }
}

/* very small phones */
@media only screen and (max-width: 320px) {
   #title {
      margin-top: 8rem;
      font-size: 24px;
   }
}