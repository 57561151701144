section {
   display: flex;
   flex-direction: column;
   justify-content: center;
   width: 100%;
   min-height: 100vh;
}

@media only screen and (max-width: 425px) {
   .title {
      margin-top: 8rem;
   }
   #about:last-child {
      margin-bottom: 2rem;
   }
}

